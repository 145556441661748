
#ds .input-code {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > div {
        position: relative;
        width: 100%;
        height: 100%;
        margin: 3px;
        &::before {
            margin-top: 100%;
            display: block;
            content: ' ';
        }
    }
    input {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: 9px;
        font-weight: bold;
        text-align: center;
        box-sizing: border-box;
        font-size: 18px;
        line-height: 18px;
        background-color: var(--input-background);
    }
}