#ds {
    .ant-input-number {
        height: var(--input-height);
        border-radius: var(--input-border-radius);
        border: 1px solid var(--input-border-color);
        background: var(--input-background);    
        color: var(--primary-color);
        > .ant-input-number-input-wrap >.ant-input-number-input {
            border-radius: 0;
            height: var(--input-height);
            font-size: 12px;
            font-weight: bold;
            line-height: 28px;
            padding-left: 6px;
        }
    }
    .ant-form-item-has-error .ant-input-number {
        border-color: var(--error-color);
    }
}