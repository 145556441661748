#ds{
    .ant-popover {
        z-index: 1000;
        .ant-popover-inner-content {
            .popover-title {
                margin: 10px 0;
                font-weight: bold;
                font-size: 14px;
            }
            .popover-footer {
                display: grid;
                grid-template-columns: auto auto;
                column-gap: 10px;
                padding: 25px 0;
            }
        }
        &.popover-menu {
            .ant-popover-inner-content {
                padding: 7px;
                display: grid;
                grid-template-columns: 1fr;
                button {
                    justify-content:start;
                    padding: 9px 13px;
                }
            }
        }
    }
}