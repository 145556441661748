#ds {
    .table {
        padding-left: 25px;
        .muted {
            opacity: .25;
        }
        .ant-table-cell {
            .ant-btn-link {
                span {
                    font-weight: normal;
                }
            }
        }
        .dot-status {
            padding: 0;
            &[disabled] {
                > i {
                    background: gray;
                }
                > span:not(.anticon) {
                    width: 80px;
                }
            }
            > i {
                margin-right: 6px;
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                padding: 0;
                transform: translateY(1px);
            }
            &[disabled] {
                > i {
                    background: gray;
                }
                > span:not(.anticon) {
                    width: 80px;
                }
            }
            > span:not(.anticon){
                width: 50px;
                font-weight: 600;
                font-size: 12px;
                vertical-align: middle;
                overflow-x: hidden;
                text-overflow: ellipsis;
                text-align: left;
                text-transform: capitalize;
            }
            span.anticon-down{
                margin-left: 5px;
                font-size: 8px;
                svg {
                    path {
                        stroke: currentColor;
                        stroke-width: 120px;
                    }
                }
            } 
            &.user-status-btn {
                &.HOLD i{
                    background-color: #FF4848;
                }
                &.OK  i{
                    background-color: #318F6F;
                }
                &.REQUIRED i{
                    background-color: #FFF618;
                }
                &.active i {
                    background: green;
                } 
                &.inactive i {
                    background-color:  var(--muted-color);
                }
            }
        }
    }
}

