#ds .ant-modal.block-modal {
    top: 50%;
    transform: translateY(-50%);
    animation-duration: 0s;
    transition: 0s;
    width: 375px!important;
    .ant-modal-content {
        padding: 27px 33px 35px 25px;
        .ant-modal-body {
            padding: 0;
            display: grid;
            grid-template-columns: auto 1fr;
            column-gap: 28px;
            img {
                margin: 0;
                grid-row-start: 1;
                grid-row-end: 3;
            }
            h2 {
                line-height: 22px;
                margin-bottom: 9px;
                font-weight: bold;
                font-size: 18px;
            }
            p {
                line-height: 18px;
                margin-bottom: 24px;
                font-size: 13px;
            }
        }
        .ant-modal-footer {
            border: none;
            padding: 0;
            margin-top: 15px;
        }
        .ant-modal-close {
            display: none;
        }
    }
}