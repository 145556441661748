#ds {
    .ql-editor {
        padding: 0;
    }
    .input-html {
        &.input-html-disabled {
            &:hover{
                border-color: transparent!important;
            }
            opacity: 0.6;
        }
        
        font-weight: normal!important;
        .ql-tooltip{
            z-index: 1000;
        }
        .ql-editor{
            padding: 0px;
            &.ql-blank::before {
                font-style: normal;
                font-size: 12px;
                font-weight: bold;
                font-family: var(--font-family);
                color: var(--disabled-color);
                left: 0px;
            }
        }
    }
}