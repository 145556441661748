#ds {
    .show-more-container {
        position: relative;
        button {
            display: none;
            padding: 0;
            > span {
                color: var(--highlight-color);
                font-size: 11px;
                font-weight: bold!important;
            }
        }
        .text {
            overflow: hidden;
            transition: max-height 150ms linear;
            &:before {
                display: none;
                content: "\00a0";
                height: 50px;
                bottom: 20px;
                left: 0;
                position: absolute;
                width: 100%;
                background: linear-gradient(transparent, #ffffffff);
            }
        } 
        &.has-more {
            button, .text:before {
                display: block;
            }
        }
          
        &.open {
            .text {
                max-height: 600px!important;
                &:before {
                    display: none;
                }
            }
        }
    }
}