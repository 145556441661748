#ds .ant-tabs {
    font-size: 13px;
    color: var(--primary-color);
    font-family: var(--font-family);
    >.ant-tabs-nav {
        &::before {
            border-color: var(--border-color);
        }
        .ant-tabs-tab{
            font-weight: 600;
            padding-bottom: 7px;
            &:not(.ant-tabs-tab-disabled) {
                color: var(--primary-color);
            }
        }
        .ant-tabs-tab-active{
            color: var(--tabs-color);
            .ant-tabs-tab-btn{
                color: var(--tabs-color);
                font-weight: 800;
            }
        }
        .ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
            background: var(--tabs-color);
        }
    }
    &.ant-tabs-top {
        >.ant-tabs-nav {
            margin-bottom: 0px;
            .ant-tabs-tab{
                font-size: 14px;
            }
        }
        .ant-tabs-ink-bar {
            height: 3px;
        }
    }
    &.ant-tabs-left {
        >.ant-tabs-nav { 
            .ant-tabs-tab {
                padding: 8px 12px;
                font-size: 13px;
                &.ant-tabs-tab-active {
                    background-color: var(--selected-color);
                }
            }
        }
        >.ant-tabs-content-holder {
            border-color: var(--border-color);
        }
        .ant-tabs-ink-bar {
            width: 3px;
        }
        .ant-tabs-tabpane {
            padding: 0!important;
        }
    }
    .ant-tabs-extra-content {
        button {
            margin-left: 10px;
            margin-bottom: 10px;
        }
    }
    &.ltabs {
        .ant-tabs-tabpane {
            height: calc(100vh - 258px);
            margin-left: var(--app-content-padding);
            overflow-y: auto;
        }
    }
    
    .tabs-scroll {
        height: calc(100vh - 258px);
        margin-left: var(--app-content-padding);
        overflow-y: auto;
        .table {
            padding-left: 0;
            margin-top: 5px;
            .ant-table-container {
                height: calc(100vh - 263px);
            }
            .ant-spin-nested-loading, 
            .ant-spin-container {
                position: static;
            }
            .ant-pagination {
                width: calc(100% - 2 *  var(--app-content-padding) );
            }
        }
    }
}
