@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=4001043f-7f8c-414a-af6e-4a5c2c21c0f8&fontids=692088,6005273");
@font-face{
    font-family:"Quicksand";
    src: url(./static/Quicksand-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face{
    font-family:"Quicksand";
    src: url(./static/Quicksand-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}