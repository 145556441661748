#ds {
    height: 100%;
    background: var(--app-background);
    color: var(--primary-color);
    #ds-app, .ant-app {
        height: 100%;
    }
    h1 {
        font-weight: bold;
        font-size: 25px;
        line-height: 31px;
        color: var(--primary-color);
    }
    h2, h3, h4 {
        color: var(--title-color);
    }
    a, p {
        color: var(--primary-color);
    }
}
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-track {
    background-color: var(--app-scrollbar-bgr);
    opacity: .6;
}
::-webkit-scrollbar-thumb {
    background-color: var(--app-scrollbar-thumb);
    border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
    background: #99a0b0; 
}
::-webkit-scrollbar-corner {
    background: var(--app-background);
}

