#ds {
    .input-phone {
        width: 100%;
        border-width: 1px;
        border-style: solid;
        border-color: var(--input-border-color);
        font-size: 12px;
        line-height: 1.67;
        border-radius: 5px;
        width: 100%;
        padding: 4px 11px;
        color: var(--colorText);
        &:focus {
            box-shadow: 0 0 0 2px rgba(5, 138, 255, 0.06);
            outline: 0;
        } 
        &:disabled {
            color: rgba(40,56,87,0.25);
        }
    }
    
    .lx-form-item-has-error .PhoneInput,
    .lx-form-item-has-error .input-phone  {
        border-color: var(--colorError);
        &:focus {
            border-color: var(--colorError);
            box-shadow: 0 0 0 2px rgba(255, 45, 5, 0.1);
            outline: 0;
        }
    }
}
