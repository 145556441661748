#ds {
    .ant-form-item.form-item-switch{
        &.form-item-switch-right {
            label {
                justify-content: flex-end;
            }
        }
        &.form-item-switch-inline {
            margin-top: 27px;
        }
        > .ant-row {
            display: grid;
            grid-template-columns: 1fr 45px;
            align-items: center;
            column-gap: 11px;
        }
    }
    .ant-switch[disabled] {
        opacity: .3;
    }
}