#ds {
    .qr-code {
        padding: 32px 0 0 0;
        align-items: center;
        .ant-qrcode {
            padding: 15px;
            border-color: var(--input-border-color);
        }
        .copy-btn {
            padding: 0;
            .anticon {
                position: absolute;
                right: 8px;
                bottom: 8px;
            }
        }
    }    
}