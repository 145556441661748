#ds {
    .ant-spin{
        .ant-spin-text {
            font-size: 13px;
            color: #b7bcc7;
        }
        &.center {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .ant-spin.spin-lazy-loading {
        position: absolute;
            top: 50%;
            left: 50%;
    }
}