#ds {
    .image-uploader {
        border: 1px var(--input-border-color) solid;
        border-radius: var(--input-border-radius);
        padding: 18px;
        
        .ant-upload-picture-card-wrapper {
            border: 1px var(--input-border-color) dashed;
            border-radius: var(--input-border-radius);
            margin-bottom: 18px;
            .ant-upload {
                border: none;
                background-color: transparent;
                height: 220px;
                font-size: 16px;
                margin: 0;
                .anticon-plus {
                    font-size: 30px;
                }
            }
        }
        .preview {
            width: 100%;
            border: 1px var(--input-border-color) solid;
            border-radius: var(--input-border-radius);
            height: 60px;
            padding: 10px;
            display: flex;
            align-items: center;
            .empty {
                opacity: .1;
                width: 40px;
                max-height: unset;
                max-width: unset;
            }
            > img {
                max-height: 40px;
                max-width: 120px;
            }
            .ant-btn-icon-only {
                position: absolute;
                right: 20px;
            }
        }
    }
}
