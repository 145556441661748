h1 {
    font-style: normal;
    font-weight: 600!important;
    font-size: 20px;
    font-family: var(--font-family);
    line-height: 20px;
    color: var(--title-color);
}
h2{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: var(--title-color);
}
.right {
    float: right;
}
fieldset {
    border: none;
    padding: 0;
}
button.center {
    margin-left: auto;
    margin-right: auto;
}

/* Margins */
.mt-xl{
    margin-top: 80px!important;
}
.mt-l{
    margin-top: 60px!important;
}
.mt-m {
    margin-top: 50px!important;
}
.m-vertical-auto {
    margin: auto;
}
.mt {
    margin-top: 40px!important;
}
.mt-s {
    margin-top: 20px!important;
}
.mt-xs {
    margin-top: 10px !important;
}
.mt-none {
    margin-top: 0 !important;
}
.mb-xxl{
    margin-bottom: 100px !important;
}
.mb-xl{
    margin-bottom: 80px !important;
}
.mb-l{
    margin-bottom: 60px;
}
.mb{
    margin-bottom: 40px !important;
}
.mb-s{
    margin-bottom: 20px !important;
}
.mb-xs{
    margin-bottom: 10px !important;
}
.mb-none{
    margin-bottom: 00px !important;
}
.mr-xl{
    margin-right: 80px;
}
.mr-l{
    margin-right: 60px;
}
.mr {
    margin-right: 40px;
}
.mr-s {
    margin-right: 20px !important;
}
.mr-xs {
    margin-right: 10px !important;
}
.mr-xl{
    margin-left: 80px;
}
.ml-l {
    margin-left: 60px;
}
.ml {
    margin-left: 40px;
}
.ml-s {
    margin-left: 20px !important;
}
.ml-xs {
    margin-left: 10px;
}
.mv {
    margin-top: 50px;
    margin-bottom: 50px; 
}
.no-m{
    margin: 0 !important;
}
.no-mb{
    margin-bottom: 0 !important;
}
.no-p {
    padding: 0!important;
}

.m-center{
    margin-left: auto;
    margin-right: auto;
}

/* Fonts */
.bold {
    font-weight: 600;
}
.no-bold {
    font-weight: 400!important;
}
.highlight {
    color: var(--error-color);
}
.success {
    color: var(--success-color);
}
.error, .error * {
    color: var(--error-color);
}
.white {
    color: white;
}
mark {
    color: var(--error-color);
    background: transparent;
    font-weight: 500;
}  
.block{
    display: block;
}

.pre {
    word-wrap: break-word; 
    white-space: pre;
    white-space: pre-wrap; 
}
.ta-center{
    text-align: center;
}
.ta-right{
    text-align: right;
}
.ta-left {
    text-align: left;
}
.anticon.large {
    font-size: 110px;
    margin-left: auto;
    margin-right: auto;
}
.fs-small{
    font-size: 13px;
}