#ds {
    .checkbox-group-vertical {
        display: grid;
        gap: 15px;
        &:after {
            display: none;
        }
        .ant-checkbox + span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    label.ant-checkbox-wrapper {
        min-height: auto;
        font-size: 13px;
        span{
            &.ant-checkbox {
                .ant-checkbox-inner {
                    border-color: var(--input-border-color);
                }
                &.ant-checkbox-checked {
                    .ant-checkbox-inner {
                        border-color: var(--primary-color);
                    }
                }
            }
        }
    }
}