#ds {
    .popover-with-form {
        min-width: 400px;
        .ant-select-selector,
        .ant-input,
        .ant-input-affix-wrapper,
        .PhoneInput, .PhoneInput input,
        .ant-select .ant-select-selector,
        .ant-input-affix-wrapper .ant-input {
            background: white;
        }
    }
}