.ant-modal {
   .switch-right {
        border-bottom: 1px solid var(--border-color);
        padding: 0!important;
        margin: 0 0 15px 16px;
        max-width: calc(100% - 32px);
        > div {
            > div {
                display: grid;
                grid-template-columns: 1fr auto auto;
                column-gap: 15px;
                .ant-col:nth-of-type(1) {
                    grid-column: 2;
                }
                .ant-col:nth-of-type(2) {
                    grid-column: 3;
                }
            }
        }
    }
}