#ds {
    .ant-select-dropdown {
        border-radius: 5px;
        .ant-select-item {
            font-size: 12px;
            color: var(--primary-color);
            .anticon {
                display: none;
            }
        }
    }
    
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background: var(--selected-color);
    }
    .ant-select{
        color: var(--primary-color);
        .ant-select-selector {
            border-radius: var(--input-border-radius);
            border-color: var(--input-border-color);
            height: unset;
            min-height: var(--input-height);
            background: transparent;
            &::after {
                line-height: unset;
            }
            span {
                font-size: 12px;
                font-weight: bold;
            }
        }
    }
    .ant-select:not(.ant-select-multiple) {
        .ant-select-dropdown {
            box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
            border-radius: 8px;
            .ant-select-item {
                font-size: 12px;
                font-weight: bold;
            }
        }
        .ant-select-selector {
            padding: 0 12px;
            .ant-select-selection-placeholder, 
            .ant-select-selection-item {
                line-height: 28px;
                >.anticon {
                    margin-right: 5px;
                }
            }
        }
        
        &.ant-select-disabled {
            > .ant-select-selector {
                border-color: var(--disabled-color);
                
            }
            .ant-select-arrow {
                display: none;
            }
        }
    }
}