#ds .lang-tabs {
    margin-bottom: 15px;
    > .ant-tabs-nav {
        margin: 12px 18px 0 18px;
        .ant-tabs-tab {
            font-weight: 600;
            font-size: 13px;
            padding: 0 12px 7px 0;
            & +.ant-tabs-tab {
                margin: 0 0 0 32px;
            }
            img {
                width: 14px;
                height: 14px;
                margin-right: 10px;
            }
        }
    }
    .ant-tabs-content-holder {
        padding: 16px 16px 0 16px;
        height: auto!important;
    }
}