.empty {
    display: block;
    text-align: center;
    > img {
        width: 50px;
    }
    > span {
        color: rgba(0, 0, 0, 0.25);
        display: block;
        margin: 0 auto;
    }
}