#ds {
    .data-header {
        height: 50px;
        margin-bottom: 5px;
        border-bottom:  1px solid var(--border-color);
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: end;
        vertical-align: bottom;
        color: var(--data-header-color);
        line-height: 14px;
        font-size: 14px;
        font-weight: 800;
        padding-bottom: 7px;
        text-transform: capitalize;
        .ant-btn-link {
            text-align: right;
            font-size: 14px;
            color: var(--title-color);
            top: -4px;
        }
    }
        
    ::placeholder {
        font-weight: bold;
        font-size: 12px;
        padding-left: 5px;
        opacity: 1;
    }
    input {
        &::placeholder {
            font-weight: bold;
        }
        &:focus::placeholder {
            color: transparent;
        }
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:focus-within,
        &:-webkit-autofill:active {
            box-shadow: 0 0 0px 1000px transparent inset !important;
            filter: none;
        }
    }
    .layout-wrapper, 
    .ant-modal-root,
    .ant-popover {
        .anticon-question-circle {
            color: var(--info-color);
            margin-left: 7px;
            svg {
                width: 11px;
                height: 11px;
            }
        }
    }
        
    // moved from global index.scss

    .ant-select-tree-checkbox-inner {
        border-radius: 0;
        border: 1px black solid;
        font-size: 13px;
    }
    .danger-text {
        color: var(--error-color);
        span { color: var(--error-color); }
    }
    .ant-alert-highlight {
        border-radius: 0;
        background: var(--error-color);
        padding: 10px 10px;
        .ant-alert-message {
            font-size: 12px;
            line-height: 18px;
            color: white;
            font-weight: 600;
            width: 95%;
            display: inline-block;
        }
        .anticon-close {
            color: var(--error-color);
            border-radius: 50%;
            background: black;
            opacity: .5;
            padding: 3px;
        }
    }

    /* GLOBAL */
    
    
    .ant-select.ant-select-show-arrow {
        .ant-select-arrow {
            color: black;
            path{
                stroke-width: 5rem;
                stroke: black;
            }
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
    }


    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        box-shadow: 0 0 0px 1000px transparent inset !important;
        transition: background-color 5000s ease-in-out 0s;
        filter: none;
    }


    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield!important;
        appearance: textfield!important;
    }

    .section-divider{
        font-size: 13px;
        line-height: 19px;
        border-bottom: 1px solid var(--border-color-base);
        margin: 30px 0 10px 0;
        color: var(--highlight-color);
    }


}