#ds {
    .module > div:first-of-type {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        background-color: var(--primary-color);
        &::after {
            content: ' ';
            display: block;
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 50%;
            filter: saturate(0) brightness(0) invert(1);
        }
    } 
    // @TODONEXT Check if it is used anywhere
    div.module.module-small {
        position: relative;
        height: 40px;
        display: grid;
        align-items: center;
        grid-template-columns: 43px 1fr;
        > div:first-child {
            background: none;
            filter: saturate(0) brightness(0) opacity(56%) drop-shadow(0 0 0 #2E4E9C);
            &:after {
                background-size: 17px 17px;
            }
        }
    }
    // Colored modules
    .module-reports > div:first-of-type {
        background: linear-gradient(88.23deg, #FF365F 6.21%, #FF0088 111.55%);
        &:after {
            background-image: url(../images/modules/administration/reports.svg);
        }
    }
    .module-alerts > div:first-of-type {
        background-color: var(--highlight-color);
        &:after {
            background-image: url(../images/modules/administration/alerts.svg);
            filter: none;
            background-size: 65%;
        }
    }
    .module-benefits > div:first-of-type {
        background: linear-gradient(236.3deg, #3B3B3B 11.91%, #1D1D1D 70.01%);
        &:after {
            background-image: url(../images/modules/administration/benefits.svg);
            filter: none;
            background-size: 70%;
        }
    }
    .module-lifeworks > div:first-of-type {
        background: none;
        &:after {
            background-image: url(../images/modules/administration/ico-lifeworks.svg);
            filter: none;
            background-size: 80%;
        }
    }
    .module-integrityLine > div:first-of-type {
        background: #3665A8;
        &:after {
            background-image: url(../images/modules/administration/ico-integrity-line.svg);
            filter: none;
            background-size: 80%;
        }
    }
    .module-incident > div:first-of-type {
        background: linear-gradient(38.1deg, #FF9E18 -0.45%, #FFF618 161.22%);
        &:after {
            background-image: url(../images/modules/administration/ico-incident.svg);
            filter: none;
            background-size: 75%;
        }
    }
    .module-WEconnect > div:first-of-type {
        background: linear-gradient(295.34deg, #6B3248 39.26%, #A58390 97.81%);;
        &:after {
            background-image: url(../images/modules/administration/ico-weconnect.svg);
            background-size: 55%;
        }
    }
    .module-immunization > div:first-of-type {
        background: linear-gradient(24.19deg, #008094 0%, #049EB0 100%);
        &:after {
            background-image: url(../images/modules/administration/ico-immunization.svg);
            background-size: 61%;
        }
    }
    .module-incidentWc > div:first-of-type {
        background: linear-gradient(38.1deg, #FF9E18 -0.45%, #FFF618 161.22%);
        &:after {
            background-image: url(../images/modules/administration/ico-wc.svg);
            filter: saturate(0) brightness(100%) invert(1);
            background-size: 55%;
        }
    }
    .module-incidentAuto > div:first-of-type {
        background: linear-gradient(38.1deg, #FF9E18 -0.45%, #FFF618 161.22%);
        &:after {
            background-image: url(../images/modules/administration/ico-auto.svg);
            filter: saturate(0) brightness(100%) invert(1);
            background-size: 55%;
        }
    }
    .module-incidentLiability > div:first-of-type {
        background: linear-gradient(38.1deg, #FF9E18 -0.45%, #FFF618 161.22%);
        &:after {
            background-image: url(../images/modules/administration/ico-liability.svg);
            filter: none;
            background-size: 55%;
        }
    }
    .module-incidentNonRevenue > div:first-of-type {
        background: linear-gradient(38.1deg, #FF9E18 -0.45%, #FFF618 161.22%);
        &:after {
            background-image: url(../images/modules/administration/ico-student.svg);
            filter: none;
            background-size: 55%;
        }
    }
    .module-incidentProperty > div:first-of-type {
        background: linear-gradient(38.1deg, #FF9E18 -0.45%, #FFF618 161.22%);
        &:after {
            background-image: url(../images/modules/administration/ico-property.svg);
            filter: none;
            background-size: 55%;
        }
    }
    .module-incidentWorkplaceViolence > div:first-of-type {
        background: linear-gradient(38.1deg, #FF9E18 -0.45%, #FFF618 161.22%);
        &:after {
            background-image: url(../images/modules/administration/ico-workplace-violence.svg);
            filter: none;
            background-size: 55%;
        }
    }
    .module-incidentHighProfileLiability > div:first-of-type {
        background: linear-gradient(38.1deg, #FF9E18 -0.45%, #FFF618 161.22%);
        &:after {
            background-image: url(../images/modules/administration/ico-high-profile-liability.svg);
            filter: none;
            background-size: 55%;
        }
    }
    .module-incidentAccessAssistance > div:first-of-type {
        background: linear-gradient(38.1deg, #FF9E18 -0.45%, #FFF618 161.22%);
        &:after {
            background-image: url(../images/modules/administration/ico-access-assistance.svg);
            filter: none;
            background-size: 55%;
        }
    }
    .module-incidentMessageCenter > div:first-of-type {
        background: linear-gradient(38.1deg, #FF9E18 -0.45%, #FFF618 161.22%);
        &:after {
            background-image: url(../images/modules/administration/ico-message-center.svg);
            filter: none;
            background-size: 55%;
        }
    }
    .module-incidentLinDirect  > div:first-of-type {
        background: linear-gradient(38.1deg, #FF9E18 -0.45%, #FFF618 161.22%);
        &:after {
            background-image: url(../images/modules/administration/ico-lin-direct.svg);
            filter: saturate(0) brightness(100%) invert(1);
            background-size: 55%;
        }
    }

    // Submodules
    .layout-submenu {
        > .submenu .item {
            &.item-screenings> div:first-child {
                background-image: url(../images/icons/screenings.svg); 
            }
            &.item-tests> div:first-child {
                background-image: url(../images/icons/tests.svg);
            }
            &.item-vaccinations> div:first-child {
                background-image: url(../images/icons/vaccination.svg); 
            }
            &.item-incidentWc > div:first-child {
                background-image: url(../images/modules/administration/ico-wc.svg); 
            } 
            &.item-incidentAuto > div:first-child {
                background-image: url(../images/modules/administration/ico-auto.svg); 
            } 
            &.item-incidentLiability > div:first-child {
                background-image: url(../images/modules/administration/ico-liability.svg); 
            } 
            &.item-incidentProperty > div:first-child {
                background-image: url(../images/modules/administration/ico-property.svg); 
            } 
            &.item-incidentNonRevenue > div:first-child {
                background-image: url(../images/modules/administration/ico-student.svg); 
            }
            &.item-incidentAccessAssistance > div:first-child {
                background-image: url(../images/modules/administration/ico-access-assistance.svg);
            }
            &.item-incidentHighProfileLiability > div:first-child {
                background-image: url(../images/modules/administration/ico-high-profile-liability.svg);
            }
            &.item-incidentWorkplaceViolence > div:first-child {
                background-image: url(../images/modules/administration/ico-workplace-violence.svg);
            }
            &.item-incidentMessageCenter > div:first-child {
                background-image: url(../images/modules/administration/ico-message-center.svg);
            }
            &.item-incidentLinDirect > div:first-child {
                background-image: url(../images/modules/administration/ico-lin-direct.svg);
            }
        }
    }

    // Module info page background
    .module-info {
        &.module-info-alerts {
            background-image: url(../images/modules/administration/backgrounds/alerts.svg);
        }
        &.module-info-benefits {
            background-image: url(../images/modules/administration/backgrounds/benefits.svg);
        }
        &.module-info-integrityLine {
            background-image: url(../images/modules/administration/backgrounds/integrityline.svg);
        }
        &.module-info-WEconnect {
            background-image: url(../images/modules/administration/backgrounds/weconnect.svg);
        }
        &.module-info-incident {
            background-image: url(../images/modules/administration/backgrounds/incident.svg);
        }
        &.module-info-incidentWc {
            background-image: url(../images/modules/administration/backgrounds/wc.svg);
        }
        &.module-info-incidentAuto {
            background-image: url(../images/modules/administration/backgrounds/auto.svg);
        }
        &.module-info-incidentLiability {
            background-image: url(../images/modules/administration/backgrounds/liability.svg);
        }
        &.module-info-incidentNonRevenue {
            background-image: url(../images/modules/administration/backgrounds/student.svg);
        }
        &.module-info-incidentProperty {
            background-image: url(../images/modules/administration/backgrounds/property.svg);
        }
        &.module-incidentAccessAssistance {
            background-image: url(../images/modules/administration/ico-access-assistance.svg);
        }
        &.module-incidentHighProfileLiability {
            background-image: url(../images/modules/administration/ico-high-profile-liability.svg);
        }
        &.module-incidentWorkplaceViolence {
            background-image: url(../images/modules/administration/ico-workplace-violence.svg);
        }
        &.module-incidentMessageCenter {
            background-image: url(../images/modules/administration/ico-message-center.svg);
        }
    }
}

