
#ds {
    .editable-content {
        width: 100%;
        height: 600px!important;
        >.ql-toolbar {
            background-color: white;
            border-top: none;
            border-color: var(--border-color);
            .ql-formats {
                margin-right: 0px;
            }
        }
        >.ql-container {
            border-color: var(--border-color);
            border-bottom: none;
            height: calc(100vh - 257px);
            padding: 0px;
            > .ql-editor {
                padding: 25px!important;
            }
        }
    }
}
.content-html {
    padding: 25px!important;
}

.ant-tabs {
    .quill.editable-content .ql-container {
        height: calc(100vh - 372px)!important;
    }
    .module-info {
        .quill.editable-content .ql-container {
            height: calc(100vh - 300px)!important;
        }
    }
}