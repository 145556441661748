#ds {
    .ant-input {
        height: var(--input-height);
        font-size: 12px;
        font-weight: bold;
        line-height: 28px;
        &:not([disabled]) {
            color: var(--primary-color);
        }
    }
    textarea {
        line-height: 12px;
    }
    .ant-input-textarea, 
    textarea {
        &.ant-input-textarea-show-count:after {
            position: absolute;
            top: -23px;
            right: 0;
            font-size: 11px;
            font-weight: bold;
            color: var(--disabled-color)
        }
        &.ant-input,
        .ant-input {
            &:not(.ant-input-borderless){
                border-color: var(--input-border-color);
            }
            height: unset;
            background: var(--input-background);
        }
    }
    .ant-input-group-addon {
        background: transparent;
    }
    .ant-form-item-control-input {
        min-height: unset;
    }
    .ant-input-affix-wrapper {
        height: var(--input-height);
        border-radius: var(--input-border-radius);
        border-color: var(--input-border-color);
        background: var(--input-background);
        padding: 0 12px 0 5px;
        .ant-input {
            background: var(--input-background);
            height: 100%;
            font-size: 12px;
            font-weight: bold;
            line-height: 28px;
            padding-left: 6px;
            
        }
        > .ant-input-suffix {
            margin-right: -6px;
            font-size: 12px;
            font-weight: bold;
            color: var(--disabled-color);
            .ant-btn-link {
                padding: 0;
                
            }
        }
    }
    .ant-form-item-control-input-content {
        > .ant-input {
            &:not(.ant-input-borderless){
                border-color: var(--input-border-color);
            
            }
            background: var(--input-background); 
            border-radius: var(--input-border-radius);
            padding-left: 6px;  
            &.ant-input-disabled {
                color: rgba(0, 0, 0, 0.25);
                border-color: var(--disabled-color);
                box-shadow: none;
                cursor: not-allowed;
                opacity: 1;
            } 
        }
    }
    // Borderless
    .ant-input-borderless, 
    .ant-input-borderless:hover, 
    .ant-input-borderless:focus, 
    .ant-input-borderless-focused, 
    .ant-input-borderless-disabled, 
    .ant-input-borderless[disabled] 
    {
        background-color: transparent;
        border: 1px solid transparent;
        box-shadow: none;
    }
    // Errors
    .ant-form-item-has-error .ant-input {
        border-color: var(--error-color)!important;
    }
    .ant-form-item-has-error .ant-input-affix-wrapper {
        border-color: var(--error-color)!important;
    }
    
}